<template>
    <section class="blockElement space position-relative pageNotFound">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-12 col-md-8">
                    <div class="errorNotFound bg-white boxed p-4 text-center">
                        <span class="mb-3 mb-md-5 d-block"><img width="450" src="/assets/images/404-Artwork.png" :alt="$t('page_found.text1')" /></span>
                        <p>{{$t('page_found.text2')}}</p>
                        <router-link to="/" class="button fillBtn zulu_btn mb-4">{{$t('page_found.text3')}}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    beforeCreate(){
        console.log('https://zulutrade.com')
        // window.open(`/404.html`,'_self');
    }
}
</script>